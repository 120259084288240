import { createStore } from 'vuex';

const store = createStore({
  state: {
    isLoggedIn: !!sessionStorage.getItem('user'),
    selectedDate: "",
  },
  mutations: {
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setSelectedDate(state, payload) {
      state.selectedDate = payload;
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
  },
  actions: {
    updateSelectedDate({ commit }, date) {
      commit("setSelectedDate", date);
    },
  },
});

export default store;
