<!-- src/components/CompetingPricesCard.vue -->
<template>
<v-card class="mx-auto my-4" max-width="500" color="grey-lighten-5" elevation="4">
    <v-card-title v-if="competitorPrices">
        Prijzen van {{ formatDate(competitorPrices['date']) }}
    </v-card-title>
    <v-card-text>
        <v-simple-table class="table-bordered">
            <template v-slot:default>
            <thead>
                <tr>
                <th>Brandstof fabrikant</th>
                <th>Diesel prijs per liter</th>
                <th>Euro95 prijs per liter</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(value, supplier) in competitorPricesWithoutLast" :key="supplier">
                <td>
                    <!-- <img :src="supplier.favicon" alt="favicon" width="16" height="16" /> -->
                    {{ supplier }}
                </td>
                <td>
                    {{ value.diesel_price }} 
                    <v-icon
                        v-if="value.change_diesel === 'up'"
                        color="black"
                        small
                    >
                        mdi-arrow-up
                    </v-icon>
                    <v-icon
                        v-if="value.change_diesel === 'down'"
                        color="black"
                        small
                    >
                        mdi-arrow-down
                    </v-icon>
                    <v-icon
                        v-if="value.change_diesel === 'equal'"
                        color="black"
                        small
                    >
                        mdi-minus
                    </v-icon>
                </td>
                <td>
                    {{ value.euro95_price }}
                    <v-icon
                        v-if="value.change_euro95 === 'up'"
                        color="black"
                        small
                    >
                        mdi-arrow-up
                    </v-icon>
                    <v-icon
                        v-if="value.change_euro95 === 'down'"
                        color="black"
                        small
                    >
                        mdi-arrow-down
                    </v-icon>
                    <v-icon
                        v-if="value.change_euro95 === 'equal'"
                        color="black"
                        small
                    >
                        mdi-minus
                    </v-icon>
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </v-card-text>
    <v-card-actions>
        <v-btn color=#FF0000 text @click="navigateToExternalUrl('https://statistieken.sidneyphilipsen.nl/d/hn3bAbBVz/brandstofprijzen-concurrenten?orgId=1')">Prijsverloop</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
    import axios from 'axios';
    import auth from '../auth';
    import { getApiBaseUrl } from '@/utils/subdomain';

    export default {
        name: 'CompetitorPricesCard',
        data () { 
            return {
                competitorPrices: [],
            }
        },
        mounted() {
            this.fetchCompetitorPrices();
        },
        computed: {
            competitorPricesWithoutLast() {
                let obj = { ...this.competitorPrices };
                delete obj[Object.keys(obj).pop()];
                return obj;
            }
        },
        methods: {
            async fetchCompetitorPrices() {
                const headers = await auth.getAuthHeaders();
                console.log("requesting data");
                axios
                .get(`${getApiBaseUrl()}/api/getCompetitorPrices`, {
                headers
                })
                .then((response) => {
                this.competitorPrices = response.data;
                console.log(response.data)
                })
                .catch((error) => {
                console.error("Error fetching data:", error);
                });
            },
            navigateToExternalUrl(url) {
                window.open(url, '_blank');
            },
            formatDate(dateString) {
                const options = {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                };
                const date = new Date(dateString);
                return date.toLocaleDateString('nl-NL', options);
            },
        },
    };
</script>

<style scoped>
.table-bordered {
  border-collapse: collapse;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-bordered th {
  background-color: #f2f2f2;
  text-align: left;
  font-weight: bold;
}
</style>