<!-- src/components/ActiveRecommendedPrice.vue -->
<template>
<v-card class="mx-auto my-4" max-width="500" color="grey-lighten-5" elevation="4">
    <v-card-title>
        Huidig geldende adviesprijs
    </v-card-title>
    <v-card-subtitle>
        <span>Ingegaan op {{ formatDate(RecommendedPrice.activation_date) }} </span>       
    </v-card-subtitle>
    <v-card-subtitle>
        <span>Volgnummer: {{ RecommendedPrice.follow_up_number }} </span> 
    </v-card-subtitle>
    <v-card-text>
        <v-simple-table class="table-bordered">
            <template v-slot:default>
            <thead>
                <tr>
                <th>Diesel adviesprijs per liter</th>
                <th>Euro95 adviesprijs per liter</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>
                    {{ RecommendedPrice.diesel_price }} 
                </td>
                <td>
                    {{ RecommendedPrice.euro95_price }}
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </v-card-text>
    <v-card-actions>
        <v-btn color=#FF0000 text @click="navigateToExternalUrl('https://statistieken.sidneyphilipsen.nl/d/Nf6m0q34k/adviesprijzen?orgId=1')">Prijsverloop</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
    import axios from 'axios';
    import auth from '../auth';
    import { getApiBaseUrl } from '@/utils/subdomain';

    export default {
        name: 'ActiveRecommendedPriceCard',
        data () { 
            return {
                RecommendedPrice: [],
            }
        },
        mounted() {
            this.fetchRecommendedPrice();
        },
        methods: {
            async fetchRecommendedPrice() {
                const headers = await auth.getAuthHeaders();
                console.log("requesting data");
                axios
                .get(`${getApiBaseUrl()}/api/getActiveRecommendedPrice`, {
                headers
                })
                .then((response) => {
                this.RecommendedPrice = response.data;
                })
                .catch((error) => {
                console.error("Error fetching data:", error);
                });
            },
            navigateToExternalUrl(url) {
                window.open(url, '_blank');
            },
            formatDate(dateString) {
                const options = {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                };
                const date = new Date(dateString);
                return date.toLocaleDateString('nl-NL', options);
            },
        },
    };
</script>

<style scoped>
.table-bordered {
  border-collapse: collapse;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-bordered th {
  background-color: #f2f2f2;
  text-align: left;
  font-weight: bold;
}
</style>