<template>
  <v-app>
    <sidebar-menu v-if="isLoggedIn"></sidebar-menu>
    <banner-header v-if="isLoggedIn && !isHomePage"></banner-header>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import SidebarMenu from './components/SidebarMenu.vue';
import BannerHeader from './components/Banner.vue';

export default {
  name: 'App',
  components: {
    SidebarMenu,
    BannerHeader,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isHomePage() {
      return this.$route.path === '/'; 
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
