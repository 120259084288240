import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify'

// Set the default base URL for API requests
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .mount('#app');
