<template>
    <div>
      <v-container>
        <v-row v-if="invoices.length">
          <v-col cols="12" sm="6" md="4" v-for="(item, index) in invoices" :key="index"> 
            <v-card color="grey-lighten-5" elevation="4">
              <v-card-title>
                <span>{{ formatDate(item.delivery_date) }}</span>
              </v-card-title>
              <v-card-subtitle>
                <v-card-text v-if="item.awaiting_invoice">
                  Factuur nog niet ontvangen
                </v-card-text>
                <v-card-text v-if="!item.awaiting_invoice">
                  {{item.invoice_number}}
                </v-card-text>
              </v-card-subtitle>

              <v-card-text>
                <v-spacer></v-spacer>
                <v-icon v-if="!item.awaiting_invoice && item.is_valid" color="green" size="78">mdi-check-circle</v-icon>
                <v-icon v-if="!item.awaiting_invoice && !item.is_valid" color="red" size="78">mdi-alert-circle</v-icon>
                <v-icon v-if="item.awaiting_invoice" color="blue" size="78">mdi-clock-outline</v-icon>
              </v-card-text>
              <v-card-actions v-if="!item.awaiting_invoice">
                <v-btn color="red" text @click="openInvoiceDialog(item.id)">Meer informatie</v-btn>
              </v-card-actions>
              <v-card-actions v-if="item.awaiting_invoice">
                <v-btn color="red" text @click="openDeliveryDialog(item.delivery_id)">Meer informatie</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="dialog" width="auto" min-width="710">
        <v-card>
          <v-card-text>
            <v-toolbar flat dense v-if="invoiceDetails">
              <v-toolbar-title v-if="invoiceDetails">Factuur {{ invoiceDetails.invoice_number }} </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn v-if="invoiceDetails" color="red" :href="'/download/' + invoiceDetails.file_location" download>Download Bestand</v-btn>
            </v-toolbar>
            <v-simple-table class="table-bordered" v-if="invoiceDetails">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Factuurdatum</th>
                    <th>Diesel prijs</th>
                    <th>Diesel (Liters)</th>
                    <th>Euro95 prijs</th>
                    <th>Euro95 (Liters)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="invoiceDetails">
                    <td>{{ formatDate(invoiceDetails.delivery_date) }}</td>
                    <td>€{{ invoiceDetails.diesel_price }}</td>
                    <td>{{ invoiceDetails.diesel_liters }}</td>
                    <td>€{{ invoiceDetails.euro95_price }}</td>
                    <td>{{ invoiceDetails.euro95_liters }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-toolbar flat dense>
              <v-toolbar-title v-if="recommendedPricesDetails">Adviesprijs {{ recommendedPricesDetails.follow_up_number }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn v-if="recommendedPricesDetails" color="red" :href="'/download/' + recommendedPricesDetails.file_location" download>Download Bestand</v-btn>
            </v-toolbar>
            <v-simple-table class="table-bordered">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Ingangsdatum</th>
                    <th>Diesel prijs</th>
                    <th>Euro95 prijs</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="recommendedPricesDetails">
                    <td>{{ formatDate(recommendedPricesDetails.activation_date) }}</td>
                    <td>€{{ recommendedPricesDetails.diesel_price }}</td>
                    <td>€{{ recommendedPricesDetails.euro95_price }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-toolbar flat dense>
              <v-toolbar-title>Levering</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn v-if="deliveriesDetails" color="red" :href="'/download/' + deliveriesDetails.file_location" download>Download Bestand</v-btn>
            </v-toolbar>
            <v-simple-table class="table-bordered">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Leverdatum</th>
                    <th>Diesel (Liters)</th>
                    <th>Euro95 (Liters)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="deliveriesDetails">
                    <td>{{ formatDate(deliveriesDetails.delivery_date) }}</td>
                    <td>{{ deliveriesDetails.diesel_liters }}</td>
                    <td>{{ deliveriesDetails.euro95_liters }}</td>
                  </tr>
                  
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" block @click="dialog = false">Sluiten</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
<script>
import axios from 'axios';
import auth from '../auth';
import { getApiBaseUrl } from '@/utils/subdomain';
  
  export default {
    
    data () {
      return {
        dialog: false,
        dialogContent: '',
        invoices: [],

        invoiceDetails: null,
        recommendedPricesDetails: null,
        deliveriesDetails: null,
      }
    },
    computed: {
      year() {
        return this.selectedDate ? parseInt(this.selectedDate.split("-")[0]) : null;
      },
      month() {
        return this.selectedDate ? parseInt(this.selectedDate.split("-")[1]) : null;
      },
      selectedDate() {
        return this.$store.state.selectedDate;
      },
    },
    methods: {
      openInvoiceDialog(invoice_id) {
        this.fetchInvoiceDetails(invoice_id);
        this.dialog = true;
      },

      openDeliveryDialog(delivery_id) {
        this.fetchDeliveryDetails(delivery_id);
        this.dialog = true;
      },

      async fetchInvoices() {
        const headers = await auth.getAuthHeaders();

        axios
        .get(`${getApiBaseUrl()}/api/getInvoices?year=${this.year}&month=${this.month}`, {
          headers
        })
        .then((response) => {
          this.invoices = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      },

      formatDate(dateString) {
        const options = {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
        };
        const date = new Date(dateString);
        return date.toLocaleDateString('nl-NL', options);
      },

      async fetchInvoiceDetails(invoice_id) {
        const headers = await auth.getAuthHeaders();
        axios.get(`${getApiBaseUrl()}/api/getInvoiceDetails`, {
            params: { invoice_id },
            headers
          })
          .then((response) => {
            this.invoiceDetails = response.data[0].invoice_details;
            this.recommendedPricesDetails = response.data[0].recommended_prices_details;
            this.deliveriesDetails = response.data[0].deliveries_details;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      },
      async fetchDeliveryDetails(delivery_id) {
        const headers = await auth.getAuthHeaders();
        axios
        .get(`${getApiBaseUrl()}/api/getDeliveryDetails`, {
          params: { delivery_id },
          headers
        })
        .then((response) => {
          this.invoiceDetails = null;
          this.deliveriesDetails = response.data[0].delivery_details;
          this.recommendedPricesDetails = response.data[0].recommended_prices_details;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      },
    },
    watch: {
      selectedDate: {
        handler() {
          this.fetchInvoices();
        },
        immediate: true, // fetch invoices when the component is created
      },
    },
  };
  </script>

<style scoped>
.table-bordered {
  border-collapse: collapse;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-bordered th {
  background-color: #f2f2f2;
  text-align: left;
  font-weight: bold;
}
</style>