<template>
  <v-container  grid-list-md class="d-flex flex-column mb-5">
    <v-row>
      <v-col cols="12" sm="6" md="4" v-for="(item, index) in deliveries" :key="index"> 
        <v-card color="grey-lighten-5" elevation="4">
          <v-card-title>
            <span>{{ formatDate(item.delivery_date) }}</span>
          </v-card-title>
          <v-card-text class="bigger-text">
            <span :class="{ positive: item.total_correction > 0, neutral: item.total_correction == 0, negative: item.total_correction < 0 }">
              €{{ item.total_correction }}
            </span>
          </v-card-text>
          <v-card-actions>
            <v-btn color=#FF0000 text @click="openDialog(item.id)">Meer informatie</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

    
  <v-spacer></v-spacer>
  <v-divider class="mb-5"></v-divider>
  <v-spacer></v-spacer>

  <v-container>
    <v-row>
      <v-col cols="12" md="6"> 
        <v-card v-if="calculatedCredit" min-height="210px" color="grey-lighten-5" elevation="4">
          <v-card-title>
            <span>Verwachte creditfactuur</span>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text class="bigger-text">
            <span :class="{ positive: calculatedCredit.total_correction > 0, neutral: calculatedCredit.total_correction == 0, negative: calculatedCredit.total_correction < 0 }">
              €{{ calculatedCredit.total_correction }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card v-if="creditNote && creditNote.length > 0" min-height="210px" color="grey-lighten-5" elevation="4">
          <v-card-title>
            <span>Creditfactuur</span>
          </v-card-title>
          <v-card-subtitle>
            <v-card-text>
              {{creditNote[0].invoice_number}}
            </v-card-text>
          </v-card-subtitle>
          <v-card-text class="bigger-text">
            <span :class="{ positive: totalCredit > 0, neutral: totalCredit == 0, negative: totalCredit < 0 }">
              €{{ totalCredit }}
            </span>
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn v-if="creditNote" color=#FF0000 :href="'/download/' + creditNote[0].file_location" download>Download creditnota</v-btn> -->
            <div v-for="(note, index) in creditNote" :key="index">
              <v-btn color=#FF0000 :href="'/download/' + note.file_location" download>Download creditnota {{ index + 1 }}</v-btn>
            </div>
          </v-card-actions>
        </v-card>
        <!-- when nothing was received yet, another card will be shown -->
        <v-card v-if="creditNote && creditNote.length == 0" min-height="210px" color="grey-lighten-5" elevation="4">
          <v-card-title>
            <span>In afwachting van creditfactuur</span>
          </v-card-title>
          <v-card-text>
            <v-icon color="blue" size="78">mdi-clock-outline</v-icon>
          </v-card-text>
          <v-card-actions>
            <v-btn color=#FF0000 text @click="navigateToExternalUrl('https://statistieken.sidneyphilipsen.nl/d/hn3bAbBVz/new-dashboard?orgId=1')">Geschiedenis</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    
  </v-container>

  <v-dialog v-model="dialog" width="auto" min-width="510">
    <v-card>
      <v-card-text>
        <v-toolbar flat dense>
          <v-toolbar-title v-if="deliveryDetails">Levering</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="deliveryDetails" color=#FF0000 :href="'/download/' + deliveryDetails.file_location" download>Download Bestand</v-btn>
        </v-toolbar>
        <v-simple-table class="table-bordered">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Leverdatum</th>
                <th>Diesel (Liters)</th>
                <th>Euro95 (Liters)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="deliveryDetails">
                <td>{{ formatDate(deliveryDetails.delivery_date) }}</td>
                <td>{{ deliveryDetails.diesel_liters }}</td>
                <td>{{ deliveryDetails.euro95_liters }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-toolbar flat dense>
          <v-toolbar-title v-if="recommendedPricesDetails">Adviesprijs {{ recommendedPricesDetails.follow_up_number }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="recommendedPricesDetails" color=#FF0000 :href="'/download/' + recommendedPricesDetails.file_location" download>Download Bestand</v-btn>
        </v-toolbar>
        <v-simple-table class="table-bordered">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Ingangsdatum</th>
                <th>Diesel prijs</th>
                <th>Euro95 prijs</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="recommendedPricesDetails">
                <td>{{ formatDate(recommendedPricesDetails.activation_date) }}</td>
                <td>€{{ recommendedPricesDetails.diesel_price }}</td>
                <td>€{{ recommendedPricesDetails.euro95_price }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-toolbar flat dense>
          <v-toolbar-title>Dagprijs</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="dayPriceDetails" color=#FF0000 :href="'/download/' + dayPriceDetails.file_location" download>Download Bestand</v-btn>
        </v-toolbar>
        <v-simple-table class="table-bordered">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Datum</th>
                <th>Diesel prijs</th>
                <th>Euro95 prijs</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="dayPriceDetails">
                <td>{{ formatDate(dayPriceDetails.date) }}</td>
                <td>€{{ dayPriceDetails.diesel_price }}</td>
                <td>€{{ dayPriceDetails.euro95_price }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-toolbar flat dense>
          <v-toolbar-title>Correctie</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-simple-table class="table-bordered">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Correctie Diesel</th>
                <th>Correctie Euro95</th>
                <th>Correctie Totaal</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="correctionDetails">
                <td>€{{ correctionDetails.correction_diesel }}</td>
                <td>€{{ correctionDetails.correction_euro95 }}</td>
                <td>€{{ correctionDetails.total_correction }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-btn color=#FF0000 block @click="dialog = false">Sluiten</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
  import axios from 'axios';
  import auth from '../auth';
  import { getApiBaseUrl } from '@/utils/subdomain';

  export default {
    data () {
      return {
        deliveries: [],

        deliveryDetails: null,
        recommendedPricesDetails: null,
        dayPriceDetails: null,
        correctionDetails: null,
        creditNote:null,
        calculatedCredit:null,
        dialog: false,
      }
    },
    computed: {
      year() {
        return this.selectedDate ? parseInt(this.selectedDate.split("-")[0]) : null;
      },
      month() {
        return this.selectedDate ? parseInt(this.selectedDate.split("-")[1]) : null;
      },
      selectedDate() {
        return this.$store.state.selectedDate;
      },
      totalCredit() {
        return this.creditNote.reduce((acc, curr) => acc + curr.credit, 0);
      },
    },
    methods: {
      openDialog(invoice_id) {
        this.getDeliveryDetails(invoice_id);
        this.dialog = true;
      },

      async getDeliveries() {
        const headers = await auth.getAuthHeaders();
        axios
        .get(`${getApiBaseUrl()}/api/getDeliveries?year=${this.year}&month=${this.month}`, {
          headers
        })
        .then((response) => {
          this.deliveries = response.data;
          console.log(response.data)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      },
      async getCalculatedCredit() {
        const headers = await auth.getAuthHeaders();
        axios
        .get(`${getApiBaseUrl()}/api/getCalculatedCredit?year=${this.year}&month=${this.month}`, {
          headers
        })
        .then((response) => {
          this.calculatedCredit = response.data;
          console.log("calculated credit:")
          console.log(this.calculatedCredit)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      },
      async getDeliveryDetails(delivery_id) {
        const headers = await auth.getAuthHeaders();
        axios
        .get(`${getApiBaseUrl()}/api/getDeliveryDetails`, {
          params: { delivery_id },
          headers
        })
        .then((response) => {
          this.deliveryDetails = response.data[0].delivery_details;
          this.recommendedPricesDetails = response.data[0].recommended_prices_details;
          this.dayPriceDetails = response.data[0].dayprice_details;
          this.correctionDetails = response.data[0].correction_details;
          console.log(response.data[0])
          
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      },
      async getCreditNote() {
        const headers = await auth.getAuthHeaders();
        axios
        .get(`${getApiBaseUrl()}/api/getCreditNote?year=${this.year}&month=${this.month}`, {
          headers
        })
        .then((response) => {
          this.creditNote = response.data;
          console.log("credit note")
          console.log(response.data)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      },
      formatDate(dateString) {
        const options = {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
        };
        const date = new Date(dateString);
        return date.toLocaleDateString('nl-NL', options);
      },
      navigateToExternalUrl(url) {
        window.open(url, '_blank');
      },
    },
    watch: {
      selectedDate: {
        handler() {
          this.getDeliveries();
          this.getCreditNote();
          this.getCalculatedCredit();
        },
        immediate: true, // fetch invoices when the component is created
      },
    },
  };
</script>

<style scoped>
.table-bordered {
  border-collapse: collapse;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-bordered th {
  background-color: #f2f2f2;
  text-align: left;
  font-weight: bold;
}

.positive {
  color: rgb(0, 201, 0);
}

.neutral {
  color: black;
}
.negative {
  color: red;
}
.min-vh-100 {
  min-height: 100vh;
}

.bigger-text {
  font-size: 2.5em; /* Adjust the value as needed */
}

</style>