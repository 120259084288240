<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="red" dark flat>
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form @submit.prevent="submitForm">
              <v-text-field
                v-model="username"
                label="Gebruikersnaam"
                name="username"
                prepend-icon="mdi-account"
                type="username"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Wachtwoord"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color=#FF0000 @click="submitForm">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from '../auth';

export default {
  name: 'LoginPage',
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    async submitForm() {
      try {
        const success = await auth.login(this.username, this.password);
        if (success == true) {
          this.$store.commit('setIsLoggedIn', true);
          this.$router.push('/');
          this.username = "";
          this.password = "";
        } else {
          // Handle errors here (e.g., show an error message)
        }
      } catch (error) {
        // Handle errors here (e.g., show an error message)
      }
    },
  },
};
</script>

<style scoped>
</style>
