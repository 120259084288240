import { createRouter, createWebHistory } from 'vue-router';
import Login from './components/Login.vue';
import Home from './components/Home.vue';
import PlattsPage from './components/PlattsPage.vue';
import InvoicesPage from './components/InvoicesPage.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'HomePage',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: '/Platts',
    name: 'PlattsPage',
    component: PlattsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/Invoices',
    name: 'InvoicesPage',
    component: InvoicesPage,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
    // Check if the route requires authentication
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // Check if the user is logged in
      const user = sessionStorage.getItem('user');
      if (user) {
        // If the user is logged in, proceed to the route
        next();
      } else {
        // If the user is not logged in, redirect to the login page
        next({ name: 'Login' });
      }
    } else {
      // If the route does not require authentication, proceed to the route
      next();
    }
});

export default router;
