<template>
    <v-dialog
        v-model="dialog"
        max-width="400px"
    >
        <v-card class="pa-4">
            <v-row class="text-center">
                <v-col cols="4">
                    <v-btn icon small @click="decrementYear">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="4" class="align-self-center text-h5">
                    {{ year }}
                </v-col>
                <v-col cols="4">
                    <v-btn icon small @click="incrementYear">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" v-for="(monthName, index) in monthNames" :key="index">
                    <v-btn
                    :color="index + 1 === selectedMonth ? 'red' : 'red-accent-1'"
                    block
                    @click="setDate(index + 1)"
                    >
                    {{ monthName }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
  export default {
    props: {
        selectedDate: {
        type: String,
        default: "",
        },
    },
    data() {
        return {
            dialog: false,
            year: new Date().getFullYear(),
            monthNames: [
            "januari",
            "februari",
            "maart",
            "april",
            "mei",
            "juni",
            "juli",
            "augustus",
            "september",
            "oktober",
            "november",
            "december",
            ],
        };
    },
    computed: {
        selectedMonth() {
            return this.selectedDate ? parseInt(this.selectedDate.split("-")[1]) : 0;
        },
    },
    methods: {
        setDate(monthnumber) {
            const newSelectedDate = `${this.year}-${monthnumber.toString().padStart(2, "0")}`;
            this.$emit("update-selected-date", newSelectedDate);
            this.dialog = false;
            console.log(newSelectedDate);
        },
        incrementYear() {
            this.year++;
        },
        decrementYear() {
            this.year--;
        },
    },
  };
  </script>