<template>
    <v-app-bar
      app
    >
      <v-spacer></v-spacer>
      <v-btn icon small @click="previousMonth">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-app-bar-title @click="resetToCurrentDate">{{ formattedSelectedDate || "No date selected" }}</v-app-bar-title>
      <v-btn icon small @click="nextMonth">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-spacer></v-spacer>

      <template v-slot:append>
        <v-btn icon @click="openDialog">
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <date-picker 
      ref="datePickerComponent"
      :selectedDate="selectedDate"
      @update-selected-date="selectedDate = $event"></date-picker>
</template>
  
<script>
  import DatePicker from '@/components/DatePicker.vue';

  export default {
    name: "BannerHeader",
    components: {
      DatePicker,
    },
    data() {
      return {
        dialog: false,
        selectedDate: this.formatDate(new Date()),
      };
    },
    computed: {
      formattedSelectedDate() {
        if (!this.selectedDate) return "No date selected";

        const dateParts = this.selectedDate.split("-");
        const year = dateParts[0];
        const monthIndex = parseInt(dateParts[1]) - 1;
        const monthNames = [
          "januari",
          "februari",
          "maart",
          "april",
          "mei",
          "juni",
          "juli",
          "augustus",
          "september",
          "oktober",
          "november",
          "december",
        ];

        this.$store.commit("setSelectedDate", this.selectedDate);

        return `${monthNames[monthIndex]} ${year}`;
      },
    },

    methods: {
      openDialog() {
        this.$refs.datePickerComponent.dialog = true;
      },
      previousMonth() {
        const [year, month] = this.selectedDate.split('-').map(Number);
        const currentDate = new Date(year, month - 1);
        const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
        const formattedDate = this.formatDate(previousMonth);

        // Save the date using Vuex store
        this.$store.commit("setSelectedDate", formattedDate);
        this.selectedDate = formattedDate;
      },
      nextMonth() {
        const [year, month] = this.selectedDate.split('-').map(Number);
        const currentDate = new Date(year, month - 1);
        const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);

        // Convert nextMonth back to YYYY-MM format
        const formattedDate = this.formatDate(nextMonth);

        // Save the date using Vuex store
        this.$store.commit("setSelectedDate", formattedDate);
        this.selectedDate = formattedDate;
      },
      resetToCurrentDate() {
        const currentDate = new Date();
        const formattedDate = this.formatDate(currentDate);

        // Save the date using Vuex store
        this.$store.commit("setSelectedDate", formattedDate);

        // Update the selectedDate data property
        this.selectedDate = formattedDate;
      },
      formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        return `${year}-${month}`;
      },
    },
    
  };
  </script>
  
<style scoped>
</style>