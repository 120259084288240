// src/auth.js
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { getApiBaseUrl } from '@/utils/subdomain';

const auth = {
  state: {
    accessToken: sessionStorage.getItem('access_token') || '',
    refreshToken: sessionStorage.getItem('refresh_token') || '',
  },
  login: async function(username, password) {
    try {
      const baseUrl = getApiBaseUrl();
      const response = await axios.post(baseUrl + '/api/login', { username, password });
      if('access_token' in response.data) {
        this.state.accessToken = response.data.access_token;
        this.state.refreshToken = response.data.refresh_token;
        sessionStorage.setItem('access_token', this.state.accessToken);
        sessionStorage.setItem('refresh_token', this.state.refreshToken);
        sessionStorage.setItem('user', username);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    }
  },
  logout: function() {
    this.state.accessToken = '';
    this.state.refreshToken = '';
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem("user");
  },
  isAuthenticated: function() {
    return !!this.state.accessToken;
  },
  refreshAccessToken: async function() {
    console.log('Refreshing access token...');
    try {
      const baseUrl = getApiBaseUrl();
      const response = await axios.post(baseUrl + '/api/refresh', {}, {
        headers: { Authorization: `Bearer ${this.state.refreshToken}` },
      });
      this.state.accessToken = response.data.access_token;
      sessionStorage.setItem('access_token', this.state.accessToken);
      return true;
    } catch (error) {
      console.error('Refresh failed:', error);
      return false;
    }
  },
  isTokenExpired: function(token) {
    if (!token) return true;
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    return decodedToken.exp < currentTime;
  },

  async getAuthHeaders() {
    if (this.isTokenExpired(this.state.accessToken)) {
      // Refresh the access token if it's expired
      const success = await this.refreshAccessToken();


      if (!success) {
        // If the refresh failed, logout the user
        console.log('Token refresh failed, user logged out')
        this.logout();
        throw new Error('Token refresh failed, user logged out');
      }
    }

    return {
      Authorization: `Bearer ${this.state.accessToken}`,
    };
  },
};

export default auth;
