

<template>
  <div>
    <competing-prices-card></competing-prices-card>
    <active-recommended-price-card></active-recommended-price-card>
    <!-- <invoices-not-received-card></invoices-not-received-card> -->
  </div>
</template>

<script>
import CompetingPricesCard from '@/components/CompetingPricesCard.vue';
import ActiveRecommendedPriceCard from './ActiveRecommendedPriceCard.vue';
// import InvoicesNotReceivedCard from './InvoicesNotReceivedCard.vue';
export default {
  name: 'HomePage',
  components: {
    CompetingPricesCard,
    ActiveRecommendedPriceCard,
    // InvoicesNotReceivedCard,
  },
};
</script>